.app-brower-version-lower {
  z-index: 9999;
  display: block;
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  background-color: #f60;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  color: #4e4e4e;
}
.app-brower-close {
  display: inline-block;
  position: absolute;
  right: 5%;
  color: #4e4e4e;
  cursor: pointer;
}
.app-brower-close:hover {
  color: red;
  font-weight: bolder;
}
