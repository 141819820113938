body,
div,
ul,
li,
ol,
p,
img,
span,
button {
  margin: 0;
  padding: 0; }

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none; }

.container {
  width: 1200px;
  margin: 0 auto; }

.container-wrap {
  width: 96%;
  margin: 20px auto; }

.fl {
  float: left; }

.fr {
  float: right; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

.m10 {
  margin: 10px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

div#driver-popover-item .driver-popover-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  div#driver-popover-item .driver-popover-footer .driver-next-btn {
    border-color: #409eff;
    border-width: 0px; }
