.footer-container {
  height: 100px;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  margin-top: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  clear: both;
}
.footer-container .footer-box {
    width: 1200px;
    margin: 0 auto;
    padding-top: 25px;
    color: #999999;
}
