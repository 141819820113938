
html,
body,
#app,
.el-container {
    height: 100%;
    background: #f2f3f7;
}
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.el-scrollbar__wrap {
    overflow-x: hidden;
}
.el-header {
    background: #409eff;
    height: 52px;
}
