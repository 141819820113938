.meeting-header {
  padding: 0 10px;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
}
.header img {
    display: inline-block;
}
.header .logout-meeting-button {
    background: #712c2c;
    border-radius: 4px;
    color: white;
    border: none;
    font-size: 16px;
}
.header .logout {
    font-size: 18px;
    color: #409eff;
    background: white;
    padding-left: 15px;
    padding-right: 15px;
    height: 35px;
    line-height: 35px;
    padding-top: 0;
}
.header .driver-fix-stacking .el-button logout el-button--text driver-highlighted-element driver-position-relative {
    color: #45a0ff;
}
