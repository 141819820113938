.timer-enter-active,
.timer-leave-active {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.timer-enter-from,
.timer-leave-to {
  opacity: 0;
}
.w-timer-alert-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.w-timer-alert-dialog .custom-alert-content {
    background: white;
    padding: 15px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    width: 300px;
}
.w-timer-alert-dialog .custom-alert-content .c-title {
      font-size: 16px;
}
.w-timer-alert-dialog .custom-alert-content .c-message {
      margin-top: 10px;
      font-size: 14px;
}
.w-timer-alert-dialog .custom-alert-content .c-message .el-icon-warning {
        color: #e6a23c;
        margin-right: 5px;
}
.w-timer-alert-dialog .custom-alert-content .c-buttons {
      margin-top: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
}
.w-timer-alert-dialog .custom-alert-content .el-button {
      margin-left: 10px;
}
